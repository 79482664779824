import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/vertical/1.jpeg'
import pic02 from '../assets/images/vertical/2.jpeg'

const Vertical = (props) => (
    <Layout>
        <Helmet>
            <title>Vertical Tanks</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Vertical Tanks</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src={pic01} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            {/* <header className="major">
                                <h3>Orci maecenas</h3>
                            </header> */}
                            <p>Store petrol on your premises legally and securely with our range of fully Vertical and fire-protected above ground petrol storage tanks. Our tanks range from 1200 litres upwards. Vertical tanks can also be supplied complete with security cabinets to accommodate equipment such as diesel and gas oil pumps, fuel management systems, tank gauges and other associated control equipment</p>
                        </div>
                    </div>
                </section>
          
                <section>
                    <div className="image">
                        <img src={pic02} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            {/* <header className="major">
                                <h3>Orci maecenas</h3>
                            </header> */}
                            <p>Our Vertical tanks can also be fitted with shutters and security locks to ensure security.</p>
                            <p>All our tanks are built to order allowing us to cater to your specific requirements.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Vertical